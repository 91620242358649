// lang.js
export default {
  en: {
    common: {
      read_more: "Read more",
    },
    routes: {
      home: "Home",
      about: "About",
      short_history: "Short History",
      main_contacts: "Main Contacts",
      international_partners: "International Partners",
      working_partners: "Working Partners",
      implementing_partners: "Implementing Partners",
      rotary: "Rotary",
      snakes_and_snakebite: "Snakes and Snakebite",
      snakes_in_nepal: "Snakes in nepal",
      most_common: "Most Common",
      a_photographic_guide: "A Photographic Guide",
      addressing_snakebite_in_nepal: "Addressing Snakebite in Nepal",
      who_publication: "WHO Publication",
      edcd_publication: "EDCD Publication",
      nepal_army: "Nepal Army",
      nepal_red_cross_society: "Nepal Red Cross Society",
      snakes_in_art: "Snakes in Art",
      snakes_in_popular_culture: "Snakes in Popular Culture",
      recent_news_articles: "Recent News Articles",
      conference_2019: "2019 International Snakebite Conference",
      background_of_the_conference: "Background of the Conference",
      conference_invited_speakers: "Conference Invited Speakers",
      conference_photos: "Conference Photos",
      conference_videos: "Conference Videos",
      conference_summary: "Conference Summary",
      recent_events: "Recent Events",
      messages: "Messages",
      main_messages: "Main Messages",
      languages_of_the_campaign: "Languages of the Campaign",
      public_service_announcements: "Public Service Announcements",
      radio_shows: "Radio Shows",
      map_of_radio_broadcast_areas: "Map of Radio Broadcast Areas",
      social_media_clips: "Social Media Clips",
      messages_from_our_friends: "Messages from our Friends",
      emergency_contact_antivenom_centers:
        "Emergency Contact Antivenom Centers",
      what_to_do_in_an_emergency: "What to do in an Emergency",
      contact_information: "Contact Information",
      nepal: "Nepal",
      province_1: "Koshi",
      madhesh: "Madhesh",
      bagmati: "Bagmati",
      gandaki: "Gandaki",
      lumbini: "Lumbini",
      karnali: "Karnali",
      sudhurpaschim: "Sudhur Paschim",
      send_us_your_comments: "Send us your Comments",
    },
  },
  np: {
    common: {
      read_more: "थप पढ्नुहोस्",
    },
    routes: {
      home: "गृह",
      about: "बारेमा",
      short_history: "संक्षिप्त इतिहास",
      main_contacts: "मुल सम्पर्कहरु",
      international_partners: "अन्तराष्ट्रिय साझेदारहरु",
      working_partners: "कार्यक्रम साझेदारहरु",
      implementing_partners: "कार्यान्वयनरत साझेदारहरु",
      rotary: "रोटरी",
      snakes_and_snakebite: "सर्पहरु र सर्पदंश",
      most_common: "ज्यादाजसो पाइने सर्पहरु",
      a_photographic_guide: "सर्पसम्बन्धी फोटो–विवरण",
      addressing_snakebite_in_nepal: "नेपालमा सर्पदंश बिरुद्घका कार्यक्रमहरु",
      who_publication: "विश्व स्वास्थ्य संगठनका प्रकाशनहरु",
      edcd_publication: "इडिसिडिका प्रकाशनहरु",
      nepal_army: "नेपाली सेना",
      nepal_red_cross_society: "नेपाल रेडक्रस सोसाइटी",
      snakes_in_art: "चित्रकलामा सर्पहरु",
      snakes_in_popular_culture: "लोक–संस्कृतीमा सर्पहरु",
      recent_news_articles: "पछिल्ला लेख रचनाहरु",
      conference_2019: "अन्तराष्ट्रिय सर्पदंश सम्मेलन–२०१९",
      background_of_the_conference: "सम्मेलनको पृष्ठभुमी",
      conference_invited_speakers: "सम्मेलनमा आमन्त्रित प्रस्तोताहरु",
      conference_photos: "सम्मेलनका फोटोहरु",
      conference_videos: "सम्मेलनका भिडियोहरु",
      conference_summary: "सम्मेलनका सारांश",
      recent_events: "पछिल्ला घटनाहरु",
      messages: "सन्देशहरु",
      main_messages: "मुख्य सन्देशहरु",
      languages_of_the_campaign: "अभियानका भाषाहरु",
      public_service_announcements: "जनहितकारी रेडियो सन्देशहरु",
      radio_shows: "रेडियो कार्यक्रमहरु",
      map_of_radio_broadcast_areas: "रेडियो कार्यक्रम प्रसारण क्षेत्रको नक्सा",
      social_media_clips: "सोशल मिडिया क्लिप्स",
      messages_from_our_friends: "सुभेच्छुकहरुको सन्देशहरु",
      emergency_contact_antivenom_centers:
        "इमर्जेन्सी एण्टीभेनम केन्द्रहरुका सम्पर्क",
      what_to_do_in_an_emergency: "इमर्जेन्सी अवस्थामा कसो गर्ने ?",
      contact_information: "सम्पर्क जानकारी",
      nepal: "नेपाल",
      province_1: "कोशी",
      madhesh: "मधेश",
      bagmati: "बागमती",
      gandaki: "गण्डकी",
      lumbini: "लुम्बिनी",
      karnali: "कर्णाली",
      sudhurpaschim: "सुदूरपश्चिम",
      send_us_your_comments: "हामीलाई आफ्नो टिप्पणी पठाउनुहोस्",
    },
  },
};

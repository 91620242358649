<template>
  <div
    id="carouselExampleInterval"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="index == 0 ? 'active' : ''"
        data-bs-interval="3000"
        v-for="(img, index) in images"
        :key="img.id"
      >
        <img
          :src="baseUrl + img.image.formats.large.url"
          class="d-block w-100"
          v-if="img.image.formats.large"
        />
        <img
          :src="baseUrl + img.image.url"
          class="d-block w-100"
          v-if="!img.image.formats.large"
        />
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleInterval"
      data-bs-slide="prev"
    >
      <span class="action-icon-wrapper">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleInterval"
      data-bs-slide="next"
    >
      <span class="action-icon-wrapper">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_UPLOAD_URL,
      isLoaded: false,
      images: [],
    };
  },
  methods: {},
  mounted() {
    this.$API.getSlider().then((data) => {
      this.images = data.data.data;

      this.isLoaded = true;
    });
  },
};
</script>

<style scoped>
.carousel-item {
  max-height: 90vh;
  height: 90vh;
  background: #ffffe5;
}

.carousel-inner img {
  object-fit: contain !important;
  height: 100%;
  width: 100%;
}

.action-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background: #00000080;
  border-radius: 50%;
}

.carousel-control-next {
  justify-content: flex-end;
  right: 1rem;
}
.carousel-control-prev {
  justify-content: flex-start;
  left: 1rem;
}
</style>

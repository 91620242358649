<template>
  <div id="top" class="main">
        <Banner/>
        <Calendar/>
        <!-- <WhoWeAre/> -->
        <!-- <our-vision/> -->
        <featured-video/>
        <NoteWorthyArticles/>
        <ContactUs/>

        
  </div>
</template>

<script>

import Banner from "./../components/Banner.vue";
import Calendar from "./../components/Calendar.vue";
import ContactUs from "./../components/ContactUs.vue";
import OurVision from "./../components/OurVision.vue";
import WhoWeAre from "./../components/WhoWeAre.vue";
import NoteWorthyArticles from "./../components/NoteWorthyArticles.vue";
import FeaturedVideo from '../components/FeaturedVideo.vue';

export default {
  name: "HomePage",
  data(){
      return{
        
      }
  },

  components: {
       Banner,
       Calendar,
       ContactUs,
       OurVision,
       WhoWeAre,
       NoteWorthyArticles,
       FeaturedVideo
  }
};
</script>






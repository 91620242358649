<template>
  <div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
          <ul
            class="navbar-nav ms-auto my-2 my-lg-0"
            style="--bs-scroll-height: 100px"
          >
            <template v-for="item in routes">
              <template v-if="!item.children">
                <NavMenuItem :title="item.title" :link="item.link" />
              </template>

              <template v-if="item.children">
                <DropdownNavMenuItem
                  :type="item.type"
                  :title="item.title"
                  :children="item.children"
                />
              </template>
            </template>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import NavMenuItem from "./NavMenuItem.vue";
import DropdownNavMenuItem from "./DropdownNavMenuItem.vue";

export default {
  data() {
    return {
      routes: [
        {
          title: "home",
          link: "/",
          type: "category-menu",
        },
        {
          title: "about",
          link: "/about",
          children: [
            {
              title: "short_history",
              link: "/about/a-short-history-of-this-project",
            },
            {
              title: "main_contacts",
              link: "/about/main-contacts",
            },
            {
              title: "international_partners",
              link: "/about/international-partners",
            },
            {
              title: "working_partners",
              link: "/about/working-partners",
            },
            {
              title: "implementing_partners",
              link: "/about/implementing-parners",
            },
          ],
        },
        {
          title: "snakes_and_snakebite",
          link: "",
          type: "category-menu",
          children: [
            {
              title: "snakes_in_nepal",
              link: "",
              children: [
                {
                  title: "most_common",
                  link: "/snakes-and-snakebite/most-common",
                },
                {
                  title: "a_photographic_guide",
                  link: "/snakes-and-snakebite/photographics-guide-to-the-venomous-snakes-of-nepal",
                },
              ],
            },
            {
              title: "addressing_snakebite_in_nepal",
              link: "/addressing-snakebite-in-nepal",
              children: [
                {
                  title: "who_publication",
                  link: "/snakes-and-snakebite/who",
                },
                {
                  title: "edcd_publication",
                  link: "/snakes-and-snakebite/edcd-publication",
                },
                {
                  title: "nepal_army",
                  link: "/snakes-and-snakebite/nepal-army",
                },
                {
                  title: "nepal_red_cross_society",
                  link: "/snakes-and-snakebite/nepal-red-cross-society",
                },
              ],
            },
            {
              title: "conference_2019",
              link: "/1st-international-snakebite-conference-in-nepal-2019",
              children: [
                {
                  title: "background_of_the_conference",
                  link: "/snakes-and-snakebite/background-of-the-conference",
                },
                {
                  title: "conference_invited_speakers",
                  link: "/snakes-and-snakebite/conference-invited-speakers",
                },
                {
                  title: "conference_photos",
                  link: "/snakes-and-snakebite/conference-photos",
                },
                {
                  title: "conference_summary",
                  link: "/snakes-and-snakebite/conference-summary",
                },
              ],
            },
            {
              title: "",
              children: [
                {
                  title: "snakes_in_art",
                  link: "/snakes-and-snakebite/snakes-in-art",
                },
                {
                  title: "snakes_in_popular_culture",
                  link: "/snakes-and-snakebite/snakes-in-popular-culture",
                },
                {
                  title: "recent_news_articles",
                  link: "/snakes-and-snakebite/recent-news-articles",
                },
              ],
            },
          ],
        },
        {
          title: "recent_events",
          link: "/recent-events",
        },
        {
          title: "messages",
          link: "",
          children: [
            { title: "main_messages", link: "/messages/main-messages" },
            {
              title: "languages_of_the_campaign",
              link: "/messages/languages-of-the-campaign",
            },
            {
              title: "public_service_announcements",
              link: "/messages/public-service-announcements",
            },
            { title: "radio_shows", link: "/messages/radio-shows" },
            {
              title: "map_of_radio_broadcast_areas",
              link: "/messages/map-of-radio-broadcast-areas",
            },
            {
              title: "social_media_clips",
              link: "/messages/social-media-clips",
            },
            {
              title: "messages_from_our_friends",
              link: "/messages/messages-from-our-friends",
            },
            {
              title: "send_us_your_comments",
              link: "/messages/send-us-your-comments",
            },
          ],
        },
        {
          title: "emergency_contact_antivenom_centers",
          link: "/emergency-contact-antivenom-centers",
          children: [
            {
              title: "what_to_do_in_an_emergency",
              link: "/emergency-contact-antivenom-centers/what-to-do-in-an-emergency",
            },
            {
              title: "contact_information",
              link: "/emergency-contact-antivenom-centers/contact-information",
            },
            {
              title: "province_1",
              link: "/emergency-contact-antivenom-centers/province-1",
            },
            {
              title: "madhesh",
              link: "/emergency-contact-antivenom-centers/madhesh",
            },
            {
              title: "bagmati",
              link: "/emergency-contact-antivenom-centers/bagmati",
            },
            {
              title: "gandaki",
              link: "/emergency-contact-antivenom-centers/gandaki",
            },
            {
              title: "lumbini",
              link: "/emergency-contact-antivenom-centers/lumbini",
            },
            {
              title: "karnali",
              link: "/emergency-contact-antivenom-centers/karnali",
            },
            {
              title: "sudhurpaschim",
              link: "/emergency-contact-antivenom-centers/sudhurpaschim",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // this.$i18n.locale = "fr";
  },
  components: {
    NavMenuItem,
    DropdownNavMenuItem,
  },
};
</script>

<style scoped></style>

<template>
  <div class="who-we-are py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-4 text-center d-flex align-items-center">
          <img
            src="/images/logo.png"
            style="height: 350px; width: 100%; object-fit: cover"
          />
        </div>
        <div class="col-md-8">
          <div class="d-flex flex-column justify-content-center p-5">
            <h2 class="sb-text-primary">
              {{
                $store.state.language == "eng" ? "Who Are We?" : "हामी को हौं?"
              }}
            </h2>

            <p class="short-desc">
              <!-- {{$store.state.language=='eng'?engText:nepText}} -->

              The Rotary Club of Kathmandu Mid-Town has helped spread awareness
              and prevention on snakebite in Nepal since 2015 under the
              technical guidance of one of Nepal’s foremost specialists in the
              area of snakebite prevention and treatment, Dr. Sanjib Kumar
              Sharma (BPKIHS, Dharan). Over the past seven years the Club’s
              foremost accomplishments have been fundraising to train 114 young
              doctors and 308 paramedics on the use of antivenom (through
              BPKIHS) and the hosting of the 1st ever International Conference
              on Snakebite in Nepal in 2-3 August 2019 (in collaboration with
              BPKIHS, SIMON and MOHP). (
              <router-link
                to="/1st-international-snakebite-conference-in-nepal-2019/background-of-the-conference"
              >
                Please see full details. </router-link
              >) The conference was inaugurated by the Right Honorable President
              of Nepal Mrs. Bidhya Devi Bhandari on 2nd Aug 2019. This initial
              work led to the creation of a country-wide multi-media campaign
              for snakebite awareness and prevention through a generous Global
              Grant from Rotary International (GG2099153). The campaign, which
              will run 2022-2026, will include: radio public service
              announcements, radio magazine talk programs, web page, social
              media, printed posters, as well as trainings for journalists,
              teachers and local health care workers. This is the first
              professionally designed and developed comprehensive program of its
              kind in Nepal and the first to be disseminated in 5+1 local
              languages (Nepali, Pashim Tharu, Awadhi, Bhojpuri and Maithili +
              English as subtitle). The campaign uses graphics developed by
              artists from one of the communities most-hard hit by snakebite as
              well as theme music provided by local artists.
            </p>
            <div>
              <router-link
                class="btn btn-outline-primary mt-3"
                to="/about/a-short-history-of-this-project"
              >
                {{ $store.state.language == "eng" ? "Learn More" : "थप सिक" }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      engText: `The Rotary Club of Kathmandu Mid-Town has helped spread awareness and prevention on snakebite in Nepal since 2015 under the technical guidance of one of Nepal’s foremost specialists in the area of snakebite prevention and treatment, Dr. Sanjib Kumar Sharma (BPKIHS, Dharan). Over the past seven years the Club’s foremost accomplishments have been fundraising to train 114 young doctors and 308 paramedics on the use of antivenom (through BPKIHS) and the hosting of the 1st ever International Conference on Snakebite in Nepal in 2-3 August 2019 (in collaboration with BPKIHS, SIMON and MOHP). (Please see full details.The conference was inaugurated by the Right Honorable President of Nepal Mrs. Bidhya Devi Bhandari on 2nd Aug 2019.

This initial work led to the creation of a country-wide multi-media campaign for snakebite awareness and prevention through a generous Global Grant from Rotary International (GG2099153). The campaign, which will run 2022-2026, will include: radio public service announcements, radio magazine talk programs, web page, social media, printed posters, as well as trainings for journalists, teachers and local health care workers. This is the first professionally designed and developed comprehensive program of its kind in Nepal and the first to be disseminated in 5+1 local languages (Nepali, Pashim Tharu, Awadhi, Bhojpuri and Maithili + English as subtitle). The campaign uses graphics developed by artists from one of the communities most-hard hit by snakebite as well as theme music provided by local artists.`,
      nepText:
        "सर्पको टोकाई नेपालको उष्णकटिबंधीय तराई क्षेत्रमा बसोबास गर्ने गरीब ग्रामीण समुदायहरुको बीचमा सबैभन्दा उपेक्षित सार्वजनिक स्वास्थ्य समस्याहरु मध्ये एक हो किनकि उच्च जनसंख्या घनत्व र व्यापक कृषि गतिविधिहरु जसले सर्पको सम्पर्कमा ल्याउँछ। यो क्षेत्र धेरै विषालु सर्प प्रजातिहरु को घर हो र त्यहाँ धेरै मृत्यु सर्पको टोकाई को कारण हरेक बर्ष हुन्छ। यसको बावजुद, देश समग्रमा एक कार्यात्मक सर्पदंश जागरूकता कार्यक्रम को अभाव छ।",
    };
  },
};
</script>

<style scoped>
.short-desc {
  line-height: 2rem;
}

img {
  /* height: 100%;
    object-fit: cover;
    width: 100%; */
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 15px;
}

.btn-outline-primary:hover {
  color: #fff;
  background: var(--primary);
}
</style>

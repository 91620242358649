<template>
  <header class>
    <div class="bg-white">
      <nav id="sticky-nav">
        <div class="container py-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="">
              <div class="d-flex align-items-center">
                <div class="logo">
                  <router-link to="/">
                    <img src="/images/logo.png" class="logo" />
                  </router-link>
                </div>

                <div class="ps-5">
                  <div class="snakebite-title h2">
                    <h2 class="title" v-if="currentLanguage == 'nep'">
                      नेपालमा सर्पदंश रोकथाम <br />
                      नेपालको निम्ति रोटरी जसको सहयोगले कोहि पनि सर्पदंशका कारण
                      मर्नुपर्ने छैन।
                    </h2>
                    <h2 class="title" v-else>
                      Nepal Snakebite Prevention <br />For a Nepal where no one
                      dies from snakebite
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-end align-items-end gap-2"
            >
              <div class="d-flex align-items-center">
                <span class="me-3 fw-medium">{{
                  $store.state.language == "eng" ? "Language" : "भाषा"
                }}</span>
                <select
                  name=""
                  id=""
                  v-model="currentLanguage"
                  class="form-control"
                  style="width: 150px"
                  @change="changeLanguage"
                >
                  <option value="nep">नेपाली</option>
                  <option value="eng">English</option>
                </select>
              </div>
              <div class="d-flex gap-2">
                <a
                  href="https://www.facebook.com/NepalSnakebitePrevention"
                  target="_blank"
                  style="text-decoration: none"
                  class="d-flex align-items-center"
                >
                  <i class="fab fa-facebook fa-2x me-3"></i>
                  <span class="fw-medium">{{
                    $store.state.language == "eng" ? "Facebook" : "फेसबुक"
                  }}</span>
                </a>

                <a
                  href="https://www.youtube.com/@NepalSnakebitePrevention"
                  target="_blank"
                  style="text-decoration: none"
                  class="d-flex align-items-center text-danger"
                >
                  <i class="fab fa-youtube fa-2x me-3"></i>
                  <span class="fw-medium">{{
                    $store.state.language == "eng" ? "Youtube" : "युतुब  "
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <AppHeaderV2 id="sticky-menu" />
      </nav>

      <!-- mobile -->
      <div class="mobile-nav py-2 shadow" id="mobile-nav">
        <div class="container d-flex align-items-center mobile-header">
          <i class="fas fa-bars bars mr-3" @click="sidebarToggle"></i>
          <div class="d-flex justify-content-between" style="width: 100%">
            <router-link to="/">
              <img src="/images/logo.png" alt="logo" class="ps-3 logo" />
            </router-link>
            <div class="ps-2">
              <h2 class="sm-title" v-if="currentLanguage == 'nep'">
                नेपालमा सर्पदंश रोकथाम <br />
                नेपालको निम्ति रोटरी जसको सहयोगले कोहि पनि सर्पदंशका कारण
                मर्नुपर्ने छैन।
              </h2>
              <h2 class="sm-title" v-else>
                Nepal Snakebite Prevention <br />For a Nepal where no one dies
                from snakebite
              </h2>
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div v-if="sidebarStatus" class="mobile-content mt-2">
            <div class="container">
              <ul class="ml-3" style="cursor: pointer">
                <li
                  v-for="(item, index) in navItems"
                  class="mt-4"
                  v-if="!item.hide"
                  @click="isActive(index)"
                  :key="item.id"
                >
                  {{ item.name }}
                  <i
                    class="fas fa-sort-down float-right"
                    v-if="item.children"
                  ></i>
                  <!-- if child routes are available -->
                  <transition name="fade">
                    <ul v-if="item.active">
                      <li
                        v-for="child in item.children"
                        class="ml-3 mt-1 child"
                        :key="child.id"
                        v-if="!child.hide"
                        @click="sidebarStatus = false"
                      >
                        <router-link
                          :to="{ name: child.name, hash: child.hash }"
                        >
                          <!-- <router-link :to="`${item.path}/${child.path}`"> -->
                          <i class="fas fa-angle-double-right"></i>

                          {{ child.name }}
                        </router-link>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from "vue";
import LargeHeader from "./LargeHeader.vue";
import AppHeaderV2 from "./AppHeaderV2.vue";

export default {
  components: {
    LargeHeader,
    AppHeaderV2,
  },
  data() {
    return {
      dropStatus: false,
      navItems: [],
      sidebarStatus: false,
      headerTitle: {
        eng: "Rotary Campaign For Snakebite Public Awareness, Prevention, and First Aid in Nepal",
        nep: "रोटरी नेपाल सपपदंशको बारेमा जनचेतना, रोकथाम र प्राथममक उपचार कार्पक्रम",
      },
      currentLanguage: "",
    };
  },

  methods: {
    languageSwitch() {
      if (this.currentLanguage == "nep") {
        localStorage.setItem("langauge", "eng");
        this.currentLanguage = "eng";
        this.$i18n.locale = "en";
      } else {
        localStorage.setItem("langauge", "nep");
        this.currentLanguage = "nep";
        this.$i18n.locale = "np";
      }
    },
    changeLanguage() {
      this.$store.commit("changeLanguage");
    },
    dropOnEvent() {
      this.dropStatus = true;
    },
    setFalse() {
      this.sidebarStatus = false;
    },
    dropOffEvent() {
      this.dropStatus = false;
    },
    navCat(index) {
      this.navItems[index].isActive = true;
      this.navItems.forEach((items) => {
        if (items != this.navItems[index]) {
          items.isActive = false;
        }
      });
    },
    sidebarToggle() {
      this.sidebarStatus = !this.sidebarStatus;
    },
    isActive(index) {
      this.navItems[index].active = !this.navItems[index].active;
      this.navItems.forEach((element, i) => {
        if (index != i) {
          element.active = false;
        }
      });
    },
  },
  mounted() {
    var vm = this;
    window.addEventListener("scroll", function (e) {
      var scrollPos = window.scrollY;
      var winHeight = window.innerHeight;
      var navElement = document.getElementById("sticky-nav");
      var navElement = document.getElementById("sticky-menu");
      if (scrollPos > navElement.clientHeight) {
        navElement.classList.add("fixed-top");
      } else {
        navElement.classList.remove("fixed-top");
      }
    });
    //
    var vm = this;
    window.addEventListener("scroll", function (e) {
      var scrollPos = window.scrollY;
      var winHeight = window.innerHeight;
      var navElement = document.getElementById("sticky-nav");
      var navElement = document.getElementById("mobile-nav");
      if (scrollPos > navElement.clientHeight) {
        navElement.classList.add("fixed-top");
      } else {
        navElement.classList.remove("fixed-top");
      }
    });
    //
  },
  created() {
    this.navItems = this.$router.options.routes;
    this.navItems.forEach((element) => {
      Vue.set(element, "active", false);
    });

    this.currentLanguage = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "eng";
  },
  computed: {
    filteredNav() {
      return this.isActive ? true : false;
    },
  },
};
</script>

<style scoped>
.justify-content-end a {
  color: #025198;
}
.logo {
  height: 120px;
  width: auto;
}
.bars {
  font-size: 30px;
  color: #444;
  cursor: pointer;
}

.title {
  color: #3457a4;
}

.title h4 {
  color: #5e717d;
  font-size: 1.3rem;
  font-family: sans-serif;
}
.title p {
  color: #5e717d;
  line-height: 15px;
  font-size: 1rem;
}

a:hover {
  /* color: var(--warning); */
}
.child :hover {
  color: var(--warning);
}

.mobile-nav,
#mobile-nav {
  display: none;
}

.sm-title {
  font-size: 16px;
}

.mobile-content {
  overflow: scroll;
  background: #f8f9fa;
  height: 100vh;
  width: 60vw;
  position: absolute;
  z-index: 99;
}
.mobile-content ul {
  padding: 0;
}
.mobile-header {
  top: 0;
  position: sticky;
}

ul li a:hover {
  color: #025198;
}

@media only screen and (max-width: 1004px) {
  .top-header {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  nav {
    display: none;
  }
  .mobile-nav,
  #mobile-nav {
    display: block;
    z-index: 99999;
    background-color: #fff;
    width: 100%;
  }
  .logo {
    height: 50px;
  }

  a {
    color: #025198;
  }
}
</style>

<template>
  <div class="py-5 calendar" style="background: #fefac3">
    <div class="container">
      <div class="row no-marginLR">
        <div class="col-md-6">
          <div class="custom-card scrollbar" id="style-1">
            <div>
              <div class="card-custom-header py-3">
                <h2
                  class="ml-2 text-center text-white subtitle border-bottom pb-4"
                >
                  <i
                    class="far fa-calendar-alt me-2"
                    style="color: var(--warning)"
                  ></i
                  >{{
                    $store.state.language == "nep"
                      ? calendarTitle.nep
                      : calendarTitle.eng
                  }}
                </h2>
              </div>
              <div v-if="!isLoaded">
                <div
                  class="d-flex justify-content-center align-items-center h-100"
                >
                  <div class="loader"></div>
                </div>
              </div>
              <div class="card-custom-body" v-if="isLoaded">
                <div
                  class="events container"
                  v-for="(ev, index) in events"
                  :key="index"
                >
                  <!-- PORTRIAT IMAGE -->
                  <div v-if="ev.image && ev.image.url && ev.is_portrait">
                    <div class="row">
                      <div class="col-md-6" style="padding-left: 0">
                        <div class="img-wrapper">
                          <img
                            :src="$store.state.baseUrl + ev.image.url"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <h4 class="event-title">{{ ev.event_name }}</h4>
                        <div>
                          <p
                            class="event-timing"
                            v-if="$store.state.language == 'eng'"
                          >
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ ev.event_date }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-else>
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ convertToNepali(ev.event_date) }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-if="ev.venue">
                            <i
                              class="fas fa-map-marker-alt events-icon mr-2"
                            ></i>
                            {{
                              $store.state.language == "eng"
                                ? "Location"
                                : "स्थान"
                            }}: {{ ev.venue }}
                          </p>
                          <p class="event-timing" v-if="ev.map">
                            <i class="fas fa-map events-icon mr-2"></i>
                            {{
                              $store.state.language == "eng" ? "Map" : "स्थान"
                            }}: {{ ev.map }}
                          </p>
                          <p class="event-timing" v-if="ev.event_time">
                            <i class="far fa-clock mr-2 events-icon"></i>
                            {{
                              $store.state.language == "eng" ? "Time" : "समय"
                            }}:
                            {{ ev.event_time }}
                          </p>
                          <p class="event-timing" v-if="ev.languages">
                            <i class="fas fa-globe-asia mr-2 events-icon"></i>
                            Languages: {{ ev.languages }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3" style="padding-left: 0">
                        <p class="event-details mb-2" v-html="ev.details"></p>
                        <hr class="bg-white" />
                      </div>
                    </div>
                  </div>

                  <!-- LANDSCAPE IMAGE -->
                  <div
                    class="row"
                    v-if="ev.image && ev.image.url && !ev.is_portrait"
                  >
                    <div class="col-md-12" style="padding-left: 0">
                      <div class="img-wrapper">
                        <img
                          :src="$store.state.baseUrl + ev.image.url"
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div>
                          <h4 class="event-title">{{ ev.event_name }}</h4>
                          <p
                            class="event-timing"
                            v-if="$store.state.language == 'eng'"
                          >
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ ev.event_date }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-else>
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ convertToNepali(ev.event_date) }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-if="ev.venue">
                            <i
                              class="fas fa-map-marker-alt events-icon mr-2"
                            ></i>
                            {{
                              $store.state.language == "eng"
                                ? "Location"
                                : "स्थान"
                            }}: {{ ev.venue }}
                          </p>
                          <p class="event-timing" v-if="ev.map">
                            <i class="fas fa-map events-icon mr-2"></i>
                            {{
                              $store.state.language == "eng" ? "Map" : "स्थान"
                            }}: {{ ev.map }}
                          </p>
                          <p class="event-timing" v-if="ev.event_time">
                            <i class="far fa-clock mr-2 events-icon"></i>
                            {{
                              $store.state.language == "eng" ? "Time" : "समय"
                            }}:
                            {{ ev.event_time }}
                          </p>
                          <p class="event-timing" v-if="ev.languages">
                            <i class="fas fa-globe-asia mr-2 events-icon"></i>
                            Languages: {{ ev.languages }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3" style="padding-left: 0">
                        <p class="event-details mb-2" v-html="ev.details"></p>
                        <hr class="bg-white" />
                      </div>
                    </div>
                  </div>

                  <!-- NO IMAGE -->
                  <div v-if="!ev.image">
                    <div class="row">
                      <div class="col-md-12">
                        <div>
                          <h4 class="event-title">{{ ev.event_name }}</h4>
                          <p
                            class="event-timing"
                            v-if="$store.state.language == 'eng'"
                          >
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ ev.event_date }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-else>
                            <i
                              class="fas fa-calendar-week events-icon mr-2"
                            ></i>
                            {{ convertToNepali(ev.event_date) }}
                            <span v-if="ev.event_end_date"
                              >To {{ ev.event_end_date }}</span
                            >
                          </p>

                          <p class="event-timing" v-if="ev.venue">
                            <i
                              class="fas fa-map-marker-alt events-icon mr-2"
                            ></i>
                            {{
                              $store.state.language == "eng"
                                ? "Location"
                                : "स्थान"
                            }}: {{ ev.venue }}
                          </p>
                          <p class="event-timing" v-if="ev.map">
                            <i class="fas fa-map events-icon mr-2"></i>
                            {{
                              $store.state.language == "eng" ? "Map" : "स्थान"
                            }}: {{ ev.map }}
                          </p>
                          <p class="event-timing" v-if="ev.event_time">
                            <i class="far fa-clock mr-2 events-icon"></i>
                            {{
                              $store.state.language == "eng" ? "Time" : "समय"
                            }}:
                            {{ ev.event_time }}
                          </p>
                          <p class="event-timing" v-if="ev.languages">
                            <i class="fas fa-globe-asia mr-2 events-icon"></i>
                            Languages: {{ ev.languages }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3" style="padding-left: 0">
                        <p class="event-details mb-2" v-html="ev.details"></p>
                        <hr class="bg-white" />
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div
                      class="col-md-12"
                      style="padding-left: 0"
                      v-if="ev.image"
                    >
                      <div class="img-wrapper">
                        <img
                          :src="$store.state.baseUrl + ev.image.url"
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>

                    <div class="col-md-12" style="padding-left: 0">
                      <p class="event-title mb-2">
                        {{
                          $store.state.language == "eng"
                            ? ev.event_name
                            : ev.nepali.event_name
                        }}
                      </p>
                    </div>
                  </div> -->
                </div>
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                  v-if="events.length < 1"
                >
                  <h4 class="text-white text-center mt-5">
                    No Events at the moment
                  </h4>
                  <p class="text-center text-white">Please Check Again Later</p>
                  <p>
                    <router-link
                      class="text-white"
                      to="/contact-us/contact-information"
                    >
                      Contact Us</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <our-vision />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Moment = require("moment");
import NepaliDate from "nepali-date-converter";
import OurVision from "./OurVision.vue";
NepaliDate.language = "np";

export default {
  components: {
    OurVision,
  },
  data() {
    return {
      calendarTitle: {
        eng: "Calendar",
        nep: "पात्रो",
      },
      currentLanguage: "",
      isLoaded: false,
      events: [],
      currentMonth: new Date().getMonth(),
      currentDate: Moment().format(),
    };
  },
  methods: {
    convertToNepali(date) {
      return new NepaliDate(new Date(date)).format("MMMM DD YYYY");
    },
  },
  computed: {},

  mounted() {
    this.currentLanguage = localStorage.getItem("language");
    this.$API
      .getEvents()
      .then((data) => {
        this.events = data.data.data;

        this.isLoaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
ul {
  list-style-type: disc;
}

a {
  color: red !important;
}

.img-wrapper {
  overflow: hidden;
}

.img-wrapper img {
  object-fit: contain;
  width: 100%;
}

.events p {
  margin-bottom: 5px;
  color: #fff;
}

.event-timing {
  margin-top: 10px;
  font-size: 16px;
}

.details {
  font-size: 16px;
}

.card-body .row {
  border-bottom: 1px solid #cccc;
  margin-top: 15px;
}

.card-custom-header {
  padding: 10px;
}

.date {
  text-align: center;
  background: #eee;
  padding: 10px 0;
  margin-top: 0px !important;
  font-weight: bold;
  font-size: 20px;
}

.designated-month {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  padding: 6px 15px;
  color: #000;
}

/* .events p{
  color:#FAA72F;
} */

.month {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  padding: 2px 2px;
  text-align: center;
  /* background: var(--azure); */
  color: #fff;
}

.event-desc {
  font-size: 14px;
  font-weight: bold;
}

.event-desc p {
  line-height: 5px;
  color: #fff;
  font-weight: bold;
}

.event-title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding: 0.5rem 0;
  /* font-weight: ; */

  /* font-weight: bold; */
}

.custom-card {
  background: var(--dark-blue);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding-bottom: 10px;
}

.card-custom-body {
}

.card-custom-body .row {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  /* background: #f7a81b; */
  display: flex;
  align-items: center;
}

hr {
  border-color: var(--warning);
}

.read-more {
  color: var(--primary-red);
  border-bottom: 1px solid var(--primary-red);
  display: table;
}

.read-more a {
  color: var(--primary-red);
  text-decoration: none;
}

.no-padding {
  padding: 0px;
}

.no-marginLR {
  margin-left: 0;
  margin-right: 0;
}

.scrollbar {
  height: 710px;
  overflow-y: scroll;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.events-icon {
  color: var(--warning) !important;
  font-size: 20px;
  min-width: 20px;
  text-align: center;
  margin-right: 15px;
}
</style>

<template>
  <footer class="bg-grey pt-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="logo-part mb-5">
            <div class="d-flex align-items-center">
              <img src="/images/logo.png" class="logo" alt="">

              <div v-if="$store.state.language == 'nep'">
                <h4 class="ms-3 footer-title">
                  नेपालमा सर्पदंश रोकथाम </h4>



                <h4 class="ms-3 footer-subtitle">नेपालको निम्ति रोटरी जसको सहयोगले कोहि पनि सर्पदंशका कारण मर्नुपर्ने छैन।
                </h4>

              </div>

              <div v-else>
                <h4 class="ms-3 footer-title">
                  Nepal Snakebite Prevention </h4>



                <h4 class="ms-3 footer-subtitle">For a Nepal where no one dies from snakebite</h4>

              </div>

            </div>


          </div>
        </div>

        <div class="footer-logo-container border-top pt-3">
          <div class="footer-logo">
            <img src="/images/logo/rotary.jpg" class="footer-logo">
          </div>

          <div class="footer-logo">
            <img src="/images/logo/bpkihs.jpg" class="footer-logo">
          </div>
          <div class="footer-logo">
            <img src="/images/logo/edcd.jpg" class="footer-logo">
          </div>
          <div class="footer-logo">
            <img src="/images/logo/nheicc.png" class="footer-logo">
          </div>
          <div class="footer-logo">
            <img src="/images/logo/who.png" class="footer-logo">
          </div>
          <div class="footer-logo">
            <img src="/images/logo/wwf.jpg" class="footer-logo">
          </div>


        </div>


      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
footer {}

.bg-grey {
  background: #fafafa;
}

.logo {
  width: 100px;
}

footer {
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  color: var(--steel-blue);
}

.footer-logo-container {
  display: flex;
}

.footer-logo {
  height: 80px;
  width: 100%;
  object-fit: contain;
}

footer p,
a {
  font-size: 0.9em;
}

.footer-title {
  color: #3457a4;
  font-size: 28px;

}

.footer-subtitle {
  color: #3457a4;
}
</style>

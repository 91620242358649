import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";

import APIService from "./api/api";
Vue.prototype.$API = new APIService();

import messages from "./lang";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en", // set the default locale
  messages,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <div class="featured-video py-5">
        <div class="d-flex justify-content-center">
            <!-- <div class="text-center mb-5" style="width:70vw;">
        <h2 class="section-title text-white" >

            {{$store.state.language=='eng'?'The first-ever international conference on snakebite in Nepal seeks antidotes to the epidemic':'नेपालमा सर्पको टोकाईको बारेमा पहिलो अन्तर्राष्ट्रिय सम्मेलनले महामारीको औषधि खोज्यो'}}
        </h2>
        </div> -->
        </div>
        <div class="d-flex justify-content-center gap-5 px-5">
            <iframe width="50%" height="471" src="https://www.youtube.com/embed/lc8bkDQuLHU?si=URUwi-NeTOLz_GyI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="50%" height="471" src="https://www.youtube.com/embed/Q3Cvph_7Sjc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>

                <iframe width="50%" height="471" src="https://www.youtube.com/embed/h51eUE4lEUs?si=QcqRGcw3eRM3R_Q0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>
</template>


<script>
export default {



}
</script>

.
<style scoped>
.featured-video {
    background-color: var(--warning)
}
</style>
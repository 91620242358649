<template>
  <div id="app">
    <AppHeader />
    <!-- <AppHeaderV2 /> -->
    <router-view class="content"></router-view>
    <AppFooter />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import AppHeaderV2 from "./components/AppHeaderV2.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
    AppHeader,
    AppFooter,
    AppHeaderV2,
  },

  mounted() {
    let currentLanguage = localStorage.getItem("language");
    if (currentLanguage) {
      this.$store.commit("initLang", currentLanguage);
    } else {
      this.$store.commit("initLang", "eng");
    }
  },
};
</script>

<style>
.content {
  min-height: 100vh;
}
</style>

<template>
  <li
    class="nav-item dropdown"
    :style="type === 'category-menu' ? 'position: unset' : ''"
  >
    <a
      class="nav-link nav-header dropdown-toggle"
      href="#"
      id="navbarScrollingDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $t("routes." + title) }}
    </a>
    <div
      class="dropdown-menu shadow p-3 mb-5 rounded"
      aria-labelledby="navbarScrollingDropdown"
    >
      <div
        class="dropdown-wrapper"
        style="display: flex"
        v-if="type === 'category-menu'"
      >
        <ul v-for="child in children">
          <span v-if="child.title" class="nav-header"
            >{{ $t("routes." + child.title) }}
          </span>
          <hr v-if="child.title" class="dropdown-divider" />
          <li v-for="childItem in child.children">
            <router-link class="dropdown-item" :to="childItem.link"
              >> {{ $t("routes." + childItem.title) }}</router-link
            >
          </li>
        </ul>
      </div>
      <ul v-else>
        <li v-for="child in children">
          <router-link class="dropdown-item" :to="child.link">{{
            $t("routes." + child.title)
          }}</router-link>
        </li>
        <!-- <li><hr class="dropdown-divider" /></li> -->
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    children: {
      required: true,
    },
    title: {
      required: true,
    },
    type: {},
  },
};
</script>

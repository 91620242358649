<template>
  <li class="nav-item nav-header">
    <router-link class="nav-link" :to="link">{{
      $t("routes." + title)
    }}</router-link>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
    },
    link: {},
  },
};
</script>

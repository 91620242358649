<template>
  <div class="blog py-5">
    <section class="container">
      <div class="section-title d-flex justify-content-between">
        <h1 class="title">
          {{
            $store.state.language == "eng"
              ? "Recent noteworthy news articles"
              : "भर्खरै उल्लेखनीय समाचार लेख"
          }}
        </h1>
        <div class="text-danger view-all text-underlined">
          <router-link to="/snakes-and-snakebite/recent-news-articles">
            {{ $store.state.language == "eng" ? "View All" : "सबै हेर्नुहोस" }}
          </router-link>
        </div>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <div class="card">
            <!-- Single Blog -->
            <a :href="item.external_link" target="_blank">
              <div class="single-blog">
                <div class="blog-img">
                  <img :src="baseUrl + item.featured_image.url" alt />
                </div>
                <div class="blog-content p-3">
                  <div class="blog-title">
                    <div class="title">
                      <h2 class="subtitle" v-html="item.title"></h2>
                    </div>
                  </div>

                  <div class="read-more mt-1">
                    <i class="fas fa-external-link-alt mr-2"></i>
                    <a :href="item.external_link" target="_blank">
                      {{
                        $store.state.language == "eng"
                          ? "Read More"
                          : "थप पढ्नुहोस्"
                      }}
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_UPLOAD_URL,
      news: [],
    };
  },

  computed: {
    filteredItems: function () {
      return this.news.slice(0, 3);
    },
  },

  mounted() {
    this.$API.getRecentArticles().then((data) => {
      this.news = data.data.data;
      console.log("DATA", this.news);
    });
  },
};
</script>

<style scoped>
.blog {
  background: #f5f5f5;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.card:hover {
  border: 1px sold #ddd;
}
a {
  text-decoration: none;
  color: #333;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.section-title {
  position: relative;
}
.section-title p {
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: 400;
}
.section-title h4 {
  font-size: 1.2em;
  font-weight: 300;
  text-transform: capitalize;
  position: relative;
  /* padding-bottom: 20px; */
  display: inline-block;
}
/* .section-title h4::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background-color: #d8d8d8;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
}
.section-title h4::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #019fcb;
  left: 0;
  bottom: 0;
  left: 50%;
  margin-left: -25px;
} */
.blog-img {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.blog-img img {
  /* width: 100%; */
  height: 100%;
}
.post-category a {
  display: inline-block;
  background-color: #019fcb;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
}
.single-blog:hover img {
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
}
.post-category {
  position: absolute;
  left: 0;
  bottom: 0;
}
.blog-content {
  padding: 30px 5px;
}
.single-blog {
  /* border: 1px solid #eee; */
}
.blog-title h4 {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 5px;
}
.meta ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.meta {
  margin-bottom: 10px;
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
}
.blog-content a.box_btn {
  display: inline-block;
  border: 1px solid #000;
  padding: 5px 15px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 10px;
}
a.box_btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #cc5b00;
  left: -100%;
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: -1;
}
a.box_btn {
  overflow: hidden;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  text-decoration: none;
}
a.box_btn:hover::before {
  left: 0;
  z-index: -1;
}

.title {
  height: 80px;
  overflow: hidden;
}

.read-more {
  color: var(--primary-red);
  border-bottom: 1px solid var(--primary-red);
  display: table;
}

.read-more a {
  color: var(--primary-red);
  text-decoration: none;
}

.summary {
  height: 70px;
  overflow: hidden;
}

.summary-text p img {
  display: none !important;
}

.view-all {
  font-size: 16px;
}
</style>

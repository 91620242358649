import Vue from "vue";
import Vuex from "vuex";
import { i18n } from "./main";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: "eng",
    baseUrl: process.env.VUE_APP_UPLOAD_URL,
  },
  actions: {},
  mutations: {
    changeLanguage(state) {
      let currentLanguage = localStorage.getItem("language");
      if (currentLanguage == "nep") {
        state.language = "eng";
        localStorage.setItem("language", "eng");
        i18n.locale = "en";
      } else if (currentLanguage == "eng") {
        state.language = "nep";
        localStorage.setItem("language", "nep");
        i18n.locale = "np";
      } else {
        state.language = "eng";
        localStorage.setItem("language", "eng");
        i18n.locale = "en";
      }
    },

    initLang(state, payload) {
      if (payload == "nep") {
        i18n.locale = "np";
      } else if (payload == "eng") {
        i18n.locale = "en";
      } else {
        i18n.locale = "en";
      }
      state.language = payload;
      localStorage.setItem("language", payload);
    },
  },
});

export default store;

<template>
<div class="container py-3">
  <div class="contact-us">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div
          class="container py-3" >
          <div>
            <h4 class="" style="text-align: left">{{$store.state.language=='eng'?'Contact Us':'सम्पर्क गर्नुहोस'}}</h4>
          </div>

          <div>
            <p class="mb-1">Kathmandu, Nepal</p>
            <p class="mb-1">Email: snakebite.nepal@gmail.com</p>

            <!-- <p class="mb-1">Phone:+977 9898989898</p> -->
          </div>
        </div>
        <div class="container pb-5">
          <form @submit.prevent="submitForm">
            <h4>{{$store.state.language=='eng'?'Leave Comment':'टिप्पणी छोड्नुहोस्'}}</h4>
            <div class="form-group mt-3">
              <input type="text" class="form-control" :placeholder="$store.state.language=='eng'?'Name..':'नाम..'" required>
            </div>
             <div class="form-group  mt-3">
              <input type="text" class="form-control" :placeholder="$store.state.language=='eng'?'Phone..':'फोन..'">
            </div>
              <div class="form-group  mt-3">
              <input type="email" class="form-control" :placeholder="$store.state.language=='eng'?'Email..':'ईमेल..'" required>
            </div>
              <div class="form-group  mt-3">
              <textarea type="text" class="form-control" :placeholder="$store.state.language=='eng'?'How can we help you..':'हामी तपाईलाई कसरी सहयोग गर्न सक्छौ..'"/>
            </div>
            <button class="btn btn-outline-primary w-100  mt-3">{{$store.state.language=='eng'?'Send':'पठाउनुहोस्'}}</button>
          </form>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56516.27689201412!2d85.29111313455002!3d27.70903193365019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1626766166239!5m2!1sen!2snp"
          width="100%"
          height="500"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div> -->
    </div>
  </div>
  </div>
</template>

<script>
export default {

  methods:{
    submitForm(){
      alert("Form has been submitted");
    },
  },
};
</script>


<style scoped>
.contact-us {
  /* height: 550px; */
}
</style>
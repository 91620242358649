<template>
    <header>
        <nav id="nav">
            <ul class="d-flex justify-content-between px-3 w-100">
                <li
                    v-for="(item,index) in navItems" :key="index*Math.random()" style="max-width: 300px">
                    <router-link :to="item.path" class="main-item"
                       v-if="!item.children">
                        {{$store.state.language=='nep'?item.nepaliName: item.name }}
                    </router-link>
                    <span
                        v-else
                        v-on:mouseenter="mouseover(index)"
                        v-on:mouseleave="mouseleave(index)"
                        @click="mouseover(index)"
                        
                        >
                       <span class="main-item">{{ $store.state.language=='nep'?item.nepaliName: item.name }}  <i class="fas fa-chevron-down ps-2"></i> </span> 
                       
                        <ul class=" dropdown"
                           :class="item.isOpen?'isOpen':''">
                            <li v-for="(child) in item.children" :key="child.name" @click="mouseleave(index)">
                                
                                <span v-if="child.isTitle" class="isTitle">{{child.name}}</span>
                                <router-link :to="item.path+'/'+child.path" :class="[child.isSubtitle && 'isSubtitle']" class="child-link" v-else>
                                    {{ $store.state.language=='nep'?child.nepaliName: child.name }}
                                </router-link>
                            </li>
                        </ul>
                    </span>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
import Vue from 'vue';
export default {
   data(){ 
   return {
        isOpen: false,
        navItems: [],
        isNextReady: true,
    }
   },
    created(){
        this.navItems = this.$router.options.routes;
        this.navItems.forEach((item,index)=>{
            Vue.set(item, 'isOpen', false);
        })
    },
    methods: {
        mouseover: function (index) {
            if(this.isNextReady){
                this.navItems[index].isOpen = true;

               this.navItems.forEach((item, innerIndex)=>{
                 if(index != innerIndex){
                    item.isOpen = false;
                 }
               })
            }
       },

        mouseleave: function (index) {
            this.navItems[index].isOpen = false;
            this.isNextReady = false;
            this.checkIsNextReady();
        },
        checkIsNextReady(){
            setTimeout(()=>{
                this.isNextReady = true;
            },50)
        }
    },
    computed:{
     
    }
}
</script>

<style scoped>
header {
    width: 100%;
    background-color: var(--dark-blue);
}

.main-item{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

#nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    margin: 0 auto;
}

#nav > ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 16px;
}

#nav > ul > li > a {
    display: block;
    height: auto;
    padding: 20px;
    color: #fff;
    text-decoration: none;
}



.child-link:hover{
     color: var(--warning);
}

#nav > ul > li > span {
    position: relative;
    display: block;
    height: auto;
    padding: 20px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.isTitle{
    border-bottom: 1px solid var(--warning);
    padding-bottom: 5px;
    cursor: default;
}
.isSubtitle{
    padding-left: 25px !important;
}
.isSubtitle::before{
    content: '>';
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    padding:  5px 10px;
    list-style-type: none;
    background-color: var(--dark-blue);
    z-index: 999000009;
    transform: translateY(-100px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.dropdown li {
    width: 300px;
    font-size: 16px;
    
    /* border-bottom: 1px solid #fff; */
    
}

.dropdown li a {
    display: block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
}

.isOpen {
    opacity: 1;
    
    transform: translateY(0);
    display: block;
}
</style>
const baseUrl = process.env.VUE_APP_API_URL;
import axios from "axios";
import moment from "moment";

class APIService {
  getEvents() {
    let todaysDate = moment().format("YYYY-MM-DD");
    return axios.get(
      baseUrl + "calendars?populate=*&filters[event_date][$gte]=" + todaysDate
    );
  }

  getSlider() {
    return axios.get(baseUrl + "banners?populate=*&sort=rank");
  }

  getMonths() {
    return axios.get(baseUrl + "designated_months?per_page=100");
  }

  getMessagesFromFriends() {
    return axios.get(baseUrl + "messages-from-friends?per_page=100&populate=*");
  }

  getRecentArticles() {
    return axios.get(baseUrl + "blogs?populate=*");
  }
  getNewsById(id) {
    return axios.get(baseUrl + "news_articles/" + id);
  }

  getRecentEvents() {
    return axios.get(
      baseUrl + "recent-events?populate=*&sort=rank&pagination[pageSize]=100"
    );
  }

  getMainContacts(lang) {
    return axios.get(baseUrl + "main-contacts?populate=*&locale=" + lang);
  }

  getVideos() {
    return axios.get(baseUrl + "conference_videos?per_page=100");
  }
  getFeaturedVideo() {
    return axios.get(baseUrl + "featured_video");
  }

  sendContactUs(data) {
    return axios.post(
      "https://rotarymidtown.org.np/admin/wp-json/contact-form-7/v1/contact-forms/127/feedback",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getPhotos() {
    return axios.get(
      "https://rotarymidtown.org.np/admin/wp-json/wp/v2/year_category"
    );
  }

  getCatwisePhotos(id) {
    return axios.get(
      "https://rotarymidtown.org.np/admin/wp-json/wp/v2/rotary_year_photos?year_category=" +
        id
    );
  }

  getFlags() {
    return axios.get(baseUrl + "flags?per_page=100");
  }
  getMessagesFrom() {
    return axios.get(baseUrl + "messages_from?per_page=100");
  }
  getMessagesFromSlug(slug) {
    return axios.get(baseUrl + "messages_from?slug=" + slug);
  }

  getInvitedSpeakers() {
    return axios.get(baseUrl + "conference-speakers?populate=*");
  }

  getConferencePhotos() {
    return axios.get(baseUrl + "conference-photos?populate=*");
  }

  getEmergencyContacts(field) {
    return axios.get(
      baseUrl +
        "contact-centers?populate=*&per_page=100&sort=rank&filters[center]=" +
        field
    );
  }
}

export default APIService;

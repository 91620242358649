import Vue from "vue";
import Router from "vue-router";

import HomePage from "./pages/HomePage";

// For meta
import VueMeta from "vue-meta";
import RouterView from "./pages/RouterView";

Vue.use(Router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      nepaliName: "गृह",
      component: HomePage,
      hide: true,
    },

    {
      path: "/about",
      name: "About",
      nepaliName: "बारेमा",
      component: RouterView,
      children: [
        {
          path: "a-short-history-of-this-project",
          name: "Short History",
          component: () => import("@/pages/About/ShortHistory"),
          nepaliName: "संक्षिप्त इतिहास",
        },
        {
          path: "main-contacts",
          name: "Main Contacts",
          component: () => import("@/pages/About/MainContacts"),
          nepaliName: "मुल सम्पर्कहरु",
        },
        {
          path: "international-partners",
          name: "International Partners",
          component: () => import("@/pages/About/InternationalPartners"),
          nepaliName: "अन्तराष्ट्रिय साझेदारहरु",
        },
        // {
        //   path: 'technical-partner',
        //   name: "Technical Partner",
        //   component: () => import('@/pages/About/TechnicalPartner'),
        //   nepaliName: "प्राबिधिक साझेदार"
        // },
        {
          path: "working-partners",
          name: "Working Partners",
          component: () => import("@/pages/About/WorkingPartners"),
          nepaliName: "कार्यक्रम साझेदारहरु",
        },
        {
          path: "implementing-parners",
          name: "Implementing Partners",
          component: () => import("@/pages/About/ImplementingPartners"),
          nepaliName: "कार्यान्वयनरत साझेदारहरु",
        },
        {
          path: "rotary-club-of-kathmandu-mid-town",
          name: "Rotary",
          component: () => import("@/pages/About/RotaryClub"),
          nepaliName: "रोटरी",
        },

        // TO DO MIGHT NEED TO REMOVE RELATED FILES
        // {
        //   path: "edcd-and-snakebite",
        //   name: "EDCD and Snakebite",
        //   component: () => import('@/pages/About/EDCDAndSnakebite'),
        // },
        // {
        //   path: "nepal-army-and-snakebite",
        //   name: "Nepal Army and Snakebite",
        //   component: () => import('@/pages/About/NepalArmyAndSnakebite'),
        // },

        // {
        //   path: "nepal-red-cross-society-and-snakebite",
        //   name: "Nepal Red Cross Society & Snakebite",
        //   component: () => import('@/pages/About/NepalRedCrossSociety'),
        // },
        // {
        //   path: "language-of-our-snakebite-campaign",
        //   name: "Languages",
        //   component: () => import('@/pages/About/LanguageOfOurSnakebite'),
        // },
        // {
        //   path: "our-friends",
        //   name: "Our Friends",
        //   component: () => import('@/pages/About/OurFriends'),
        // },
      ],
    },
    {
      path: "/snakes-and-snakebite",
      name: "Snakes and Snakebite",
      nepaliName: "सर्पहरु र सर्पदंश",
      component: RouterView,
      children: [
        {
          path: "",
          name: "Snakes in Nepal",
          isTitle: true,
          nepaliName: "नेपालमा पाइने सर्पहरु",
        },
        {
          path: "most-common",
          name: "Most Common",
          component: () => import("@/pages/SnakesAndSnakebite/TheBigFour"),
          isSubtitle: true,
          nepaliName: "ज्यादाजसो पाइने सर्पहरु",
        },
        {
          path: "photographics-guide-to-the-venomous-snakes-of-nepal",
          name: "A Photographic Guide",
          component: () =>
            import(
              "@/pages/SnakesAndSnakebite/PhotographicGuideToVenomousSnakes"
            ),
          isSubtitle: true,
          nepaliName: "सर्पसम्बन्धी फोटो–विवरण",
        },

        {
          path: "/addressing-snakebite-in-nepal",
          name: "Addressing Snakebite in Nepal",
          isTitle: true,
          nepaliName: "नेपालमा सर्पदंश बिरुद्घका कार्यक्रमहरु",
        },

        {
          path: "who",
          name: "WHO Publication",
          component: () => import("@/pages/SnakesAndSnakebite/WHO"),
          isSubtitle: true,
          nepaliName: "विश्व स्वास्थ्य संगठनका प्रकाशनहरु",
        },
        {
          path: "edcd-publication",
          name: "EDCD Publication",
          component: () => import("@/pages/SnakesAndSnakebite/EDCD"),
          isSubtitle: true,
          nepaliName: "इडिसिडिका प्रकाशनहरु",
        },

        // {
        //   path: "",
        //   name: "Treatment Centers where EDCD Supplies Antivenom",
        //   isTitle: true,
        // },

        {
          path: "nepal-army",
          name: "Nepal Army",
          component: () => import("@/pages/EmergencyContact/NepalArmyCenters"),

          isSubtitle: true,
          nepaliName: "नेपाली सेना",
        },
        {
          path: "nepal-red-cross-society",
          name: "Nepal Red Cross Society",
          component: () => import("@/pages/About/NepalRedCrossSociety"),
          nepaliName: "नेपाल रेडक्रस सोसाइटी",
          isSubtitle: true,
        },

        //

        {
          path: "snakes-in-art",
          name: "Snakes in Art",
          component: () => import("@/pages/SnakesAndSnakebite/SnakesInArt"),
          nepaliName: "चित्रकलामा सर्पहरु",
        },

        {
          path: "snakes-in-popular-culture",
          name: "Snakes in Popular Culture",
          component: () =>
            import("@/pages/SnakesAndSnakebite/SnakesInPopularCulture"),
          nepaliName: "लोक–संस्कृतीमा सर्पहरु",
        },

        {
          path: "recent-news-articles",
          name: "Recent news articles",
          component: () =>
            import("@/pages/SnakesAndSnakebite/RecentNewsArticles"),
          nepaliName: "पछिल्ला लेख रचनाहरु",
        },

        // MIGHT NEED TO DELTE RELATED FILES
        // {
        //   path: "prevent-snakebite",
        //   name: "Prevent Snakebite ",
        //   component: () => import('@/pages/SnakesAndSnakebite/PreventSnakebite'),
        // },
        // {
        //   path: "simple-first-aid",
        //   name: "Simple First Aid",
        //   component: () => import('@/pages/SnakesAndSnakebite/SimpleFirstAid'),
        // },

        // {
        //   path: "ministry-of-health-and-population",
        //   name: "Ministry of Health and Population",
        //   component: () => import('@/pages/SnakesAndSnakebite/MinistryOfHealthAndPopulation'),
        // },

        {
          path: "/1st-international-snakebite-conference-in-nepal-2019",
          name: "2019 International Snakebite Conference",
          noPadding: false,
          nepaliName: "अन्तराष्ट्रिय सर्पदंश सम्मेलन–२०१९",
          component: RouterView,
          isTitle: true,
        },
        {
          path: "background-of-the-conference",
          name: "Background of the Conference",
          component: () => import("@/pages/Conference/BackgroundOfConference"),
          nepaliName: "सम्मेलनको पृष्ठभुमी",
          isSubtitle: true,
        },
        {
          path: "conference-invited-speakers",
          name: "Conference Invited Speakers",
          component: () =>
            import("@/pages/Conference/ConferenceInvitedSpeakers"),
          nepaliName: "सम्मेलनमा आमन्त्रित प्रस्तोताहरु",
          isSubtitle: true,
        },

        {
          path: "conference-photos",
          name: "Conference Photos",
          component: () => import("@/pages/Conference/ConferencePhotos"),
          nepaliName: "सम्मेलनका फोटोहरु",
          isSubtitle: true,
        },
        {
          path: "conference-videos",
          name: "Conference Videos",
          component: () => import("@/pages/Conference/ConferenceVideos"),
          nepaliName: "सम्मेलनका भिडियोहरु",
          isSubtitle: true,
        },
        {
          path: "conference-summary",
          name: "Conference Summary",
          component: () => import("@/pages/Conference/ConferenceSummary"),
          nepaliName: "सम्मेलनका सारांश",
          isSubtitle: true,
        },
      ],
    },
    {
      path: "/recent-events",
      name: "Recent Events",
      component: () => import("@/pages/RecentEvents"),
    },

    {
      path: "/messages",
      name: "Messages",
      nepaliName: "सन्देशहरु",
      component: RouterView,
      children: [
        {
          path: "main-messages",
          name: "Main Messages",
          component: () => import("@/pages/Messages/MainMessages"),
          nepaliName: "मुख्य सन्देशहरु",
        },
        {
          path: "languages-of-the-campaign",
          name: "Languages of the Campaign",
          component: () =>
            import("@/pages/Messages/LanguageOfOurSnakebiteCampaign"),
          nepaliName: "अभियानका भाषाहरु",
        },
        {
          path: "public-service-announcements",
          name: "Public Service Announcements",
          component: () =>
            import("@/pages/Messages/PublicServiceAnnouncements"),
          nepaliName: "जनहितकारी रेडियो सन्देशहरु",
        },
        {
          path: "radio-shows",
          name: "Radio Shows",
          component: () => import("@/pages/Messages/RadioShows"),
          nepaliName: "रेडियो कार्यक्रमहरु",
        },
        {
          path: "map-of-radio-broadcast-areas",
          name: "Map of Radio Broadcast Areas",
          component: () => import("@/pages/Messages/MapOfRadioBroadcastAreas"),
          nepaliName: "रेडियो कार्यक्रम प्रसारण क्षेत्रको नक्सा",
        },
        {
          path: "social-media-clips",
          name: "Social Media Clips",
          component: () => import("@/pages/Messages/SocialMediaClips"),
        },
        {
          path: "messages-from-our-friends",
          name: "Messages from our Friends",
          component: () => import("@/pages/Messages/MessagesFromOurFriends"),
          nepaliName: "सुभेच्छुकहरुको सन्देशहरु",
        },
        {
          path: "send-us-your-comments",
          name: "Send us your Comments",
          component: () => import("@/pages/Messages/SendYourComments"),
          nepaliName: "सुभेच्छुकहरुको सन्देशहरु",
        },
      ],
    },

    {
      path: "/emergency-contact-antivenom-centers",
      name: `Emergency Contact Antivenom Centers`,
      noPadding: false,
      nepaliName: "इमर्जेन्सी एण्टीभेनम केन्द्रहरुका सम्पर्क",
      component: RouterView,
      children: [
        {
          path: "what-to-do-in-an-emergency",
          name: "What to do in an Emergency",
          component: () => import("@/pages/EmergencyContact/WhatToDo"),
          nepaliName: "इमर्जेन्सी अवस्थामा कसो गर्ने ?",
        },
        {
          path: "contact-information",
          name: "Contact Information by Province",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "सम्पर्क जानकारी",
        },
        {
          path: "nepal",
          name: "Nepal",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "नेपाल",
        },
        {
          path: "province-1",
          name: "Province 1",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "प्रदेश नं. १",
        },
        {
          path: "madhesh",
          name: "Madhesh",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "प्रदेश नं. १",
        },
        {
          path: "bagmati",
          name: "Bagmati",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "बागमती",
        },
        {
          path: "gandaki",
          name: "Gandaki",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "गण्डकी ",
        },
        {
          path: "lumbini",
          name: "Lumbini",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "लुम्बिनी ",
        },
        {
          path: "karnali",
          name: "Karnali",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "कर्णाली",
        },
        {
          path: "sudhurpaschim",
          name: "SudhurPaschim",
          component: () =>
            import("@/pages/EmergencyContact/ContactInformation"),
          nepaliName: "सुदूरपश्चिम",
        },

        // Delete these component?
        // {
        //   path: "map-of-centers",
        //   name: "Map of Centers",
        //   component: () => import('@/pages/EmergencyContact/MapOfCenters'),
        //   nepaliName: "सर्पर्दशं उपचार केन्द्रहरुको नक्सा"
        // },
        // {
        //   path: "edcd-centers",
        //   name: "EDCD Centers",
        //   component: () => import('@/pages/EmergencyContact/EDCD'),
        //   nepaliName: "इडिसिडि उपचार केन्द्रहरु"
        // },
        // {
        //   path: "nepal-army-centers",
        //   name: "Nepal Army Centers",
        //   component: () => import('@/pages/EmergencyContact/NepalArmyCenters'),
        //   nepaliName: ""
        // },
        // {
        //   path: "nepal-red-cross-centers",
        //   name: "Nepal Red Cross Centers",
        //   component: () => import('@/pages/EmergencyContact/NepalRedCrossCenters'),
        //   nepaliName: ""
        // },
      ],
    },

    // {
    //   path: '/contact',
    //   name: 'Contact',
    //   nepaliName: "हाम्रो सम्पर्क ",
    //   component: HomePage,
    //   hide:true,
    //   children:[
    //     {
    //       path: "/",
    //       name: "snakebite.nepal \n @gmail.com",
    //     },
    //     {
    //       path: "/",
    //       name: "Leave Comments",
    //     }

    //   ]
    // }

    // {
    //   path: '*',
    //   component: PageNotFound,
    //   hide: true

    // }
  ],
});

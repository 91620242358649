<template>
  <div class="message h-100">
    <div class="container">
      <div class="row h-100 pt-2">
        <div class="col-md-6">
          <div class="message-title">
            <!-- <i class="fas fa-eye"></i>  -->
            <img src="/images/snake1.png" class="little-snakes" alt="" />

            {{$store.state.language=='eng'?vision.engTitle:vision.nepTitle}}
          </div>
          <div class="message-desc">
            {{$store.state.language=='eng'?vision.engDesc:vision.nepDesc}}
          </div>
        </div>

        <div class="col-md-6">
          <div class="message-title">
            <!-- <i class="fas fa-rocket"></i>     -->
            <img src="/images/snake2.png" class="little-snakes" alt="" />
            {{$store.state.language=='eng'?mission.engTitle:mission.nepTitle}}
          </div>
          <div class="message-desc">
            {{$store.state.language=='eng'?mission.engDesc:mission.nepDesc}}
          </div>
        </div>

        <div class="col-md-6 mt-4">
          <div class="message-title">
            <img src="/images/snake3.png" class="little-snakes" alt="" />
            <!-- <i class="fas fa-bullseye"></i>   -->
            {{$store.state.language=='eng'?goal.engTitle:goal.nepTitle}}
          </div>
          <div class="message-desc">
           {{$store.state.language=='eng'?goal.engDesc:goal.nepDesc}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            vision:{
                nepTitle: "हाम्रो भाबी सोच",
                engTitle: "Our vision",
                nepDesc : "यस्तो नेपाल जहााँकोही पमन सपपदंशबाट मदैनन्।",
                engDesc : "A Nepal where no one dies from snakebites."
            },
            mission:{
                nepTitle: "हाम्रो ध्येय",
                engTitle: "Our Mission",
                nepDesc : "समुदायहरूसँग जानकारी बाँड्नका लागि ताकि उनीहरू सर्पको टोकाइलाई कसरी रोक्ने र कसरी प्रतिक्रिया दिने भन्ने बारे राम्रोसँग जानकारी छन्।सर्पदंश भएपछि उपयुक्त प्राथमिक उपचार कसरी गर्ने भन्ने बारे राम्रोसँग जानकारी छन्।",
                engDesc : "To share information with communities so that they are well-informed on how to prevent snakebites and know how to respond with appropriate first-aid when snakebites do occur."
            },
            goal:{
                nepTitle: "हाम्रो लक्ष्य",
                engTitle: "Our Goal",
                nepDesc : "नेपालमा सर्पदंशका कारण बिरामी र मृत्युदर घटाउन।",
                engDesc : "To reduce the occurrence of morbidity and mortality due to snakebite in Nepal"
            }
        }
    }
}
</script>

<style scoped>
.message {
  background: #f5f5f5;
  padding: 100px 0;
}

.message-title {
  font-size: 28px;

  font-weight: 500;
}

.message-title i {
  color: var(--danger);
  margin-right: 5px;
}

.message-desc {
  margin-top: 18px;
  font-size: 18px;
  text-align: justify;
  color: #636363;
}

.little-snakes {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
</style>